<template>
  <div class="page1">
    <EForm :formColumns="formColumns"
           :rowSize="4"
           :optionsBtn="true"
           :actionBtn="false"
           :formData="searchForm"
           ref="form"
           :searchFlag="true"
           url="payerFeeCollectExport"
           fileName="收费员收费统计"
           :exportData="searchForm"
           @onSearch="getData"
    >
    </EForm>
<!--    <div class="mt20 fs15">-->
<!--      统计时间 ：{{searchForm.payTimeStart||'-'}}至{{searchForm.payTimeEnd||'-'}}-->
<!--    </div>-->
    <ETable :tableTitle="tableTitle"
            :tableData="tableData"
            :needPagination="false"
            :showSummary="true"
            :getSummaries="getSummaries"
            :needIndex="false"
    >
    </ETable>

  </div>
</template>

<script>
  import ETable from '@/components/ETable'
  import Http from "@/service/http";
  import EDialog from '@/components/EDialog'
  import EButton from '@/components/EButton'
  import EForm from '@/components/EForm'
  import {identity_type, status} from '@/assets/js/config'
  import vxRule from "@/assets/js/formValidate";

  export default {
    name: 'chargeStatistics',
    data() {
      return {
        tableTitle: [
          {
            label: '姓名',
            prop: 'chargePersonName'
          },
          {
            label: '笔数',
            prop: 'feeNum'
          },
          {
            label: '收费金额',
            prop: 'totalAmount'
          },
        ],
        formColumns: [
          {
            title: '开始日期',
            type: 'datePicker',
            property: 'payTimeStart',
            show: true
          },
          {
            title: '截止日期 ',
            type: 'datePicker',
            property: 'payTimeEnd',
            show: true
          },
        ],
        tableData: [],
        searchForm: {
          payTimeStart: null,
          payTimeEnd: null,
          current: 1,
          size: 10,
        },
        count: null,
        exportData: {},
        dialogVisible: false,
        labelPosition: 'right',
        labelWidth: '100px',
        form: {},
        total:{
          feeNumSum:0,
          totalAmountSum:0
        }
      }
    },
    watch: {},
    created() {
      this.getData()
    },
    components: {ETable, EDialog, EButton, EForm},
    beforeMount() {

    },
    methods: {
      getSummaries(param) {
        const sums = [];
        sums[0] = '合计';
        sums[1] = this.total.feeNum;
        sums[2] =  this.total.totalAmount;
        return sums;
      },
      async getData() {
        let res = await Http.getPayerFeeCollectList(this.searchForm)
        if (res.code == 200) {
          this.tableData = res.data.feeList
          this.total = res.data.sumData
        }
      },
    }
  }
</script>

<style lang="scss" scoped>
  .ws {
    width: 100%;
  }
</style>
